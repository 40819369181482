import { columnsType } from '@/common/models/model';
import { HeirachyTable } from '@/modules/gridify/shared/components/heirarchyTable';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@mui/material';

export const PowerManagement = ({
  data,
  defaultSortColumn,
  onTableChange,
  state,
  SiteAreaName,
  ChargerName,
  showconnector,
  onPageChange,
  onRowsPerPageChange,
  page
}: any) => {
  const dataTableRef = useRef<any>();
  const { t: translate } = useTranslation();

  const loadcolumns: columnsType[] = [
    {
      id: 'siteAreaName',
      label: `${translate('site_areas.title')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 120,
      allowSort: false,
    },
    {
      id: 'chargerName',
      label: `${translate('gridify.demand_view.charger_id')}`,
      minWidth: 50,
      textAlign: 'start',
      maxWidth: 80,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'connectors',
      label: `${translate('gridify.charger_configuration.connectors')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 60,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'connectorId',
      label: `${translate('gridify.power_managment.conectorid')}`,
      minWidth: 50,
      textAlign: 'start',
      maxWidth: 60,
      allowSort: false,
      projectField: 'communication',
    },
    {
      id: 'phase',
      label: `${translate('gridify.power_managment.phase')}`,
      minWidth: 40,
      textAlign: 'center',
      maxWidth: 40,
      allowSort: false,
      projectField: 'phase',
    },

    {
      id: 'voltage',
      label: `${translate('gridify.power_managment.voltage')}`,
      minWidth: 50,
      textAlign: 'center',
      maxWidth: 80,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'empty1',
      label: '',
      minWidth: 2,
      textAlign: 'start',
      maxWidth: 2,
    },
    {
      id: 'rating',
      label: `${translate('gridify.power_managment.sitearea_rating_kw')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 80,
      allowSort: false,
      projectField: 'rating',
    },

    {
      id: 'demand',
      label: `${translate('Demand Setpoint (kW)')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 80,
      allowSort: false,
      projectField: 'demand',
    },
    {
      id: 'instantkw',
      label: `${translate('gridify.power_managment.Instant_power_kw')}`,
      minWidth: 60,
      textAlign: 'center',
      tooltip: 'Instant Power vs Installed Capacity',
      maxWidth: 100,
      allowSort: false,
      projectField: 'siteArea.name',
    },
  ];
  const InstantPowerCell = (row: any) => {
    if (!Object.keys(row).includes('currentInstantWatts')) return null;
  
    const currentWatts = parseFloat(row?.currentInstantWatts || 0);
    const maxPower = parseFloat(row?.power || 0);
    const progressValue = maxPower ? (currentWatts / maxPower) * 100 : 0;
  
    return (
        <div style={{ margin: '0 16px' }}>
          <div
            className={`d-flex power-bar-text ${
            maxPower === 0 && 'power-bar-text-error'
            }`}
          >
          {(currentWatts / 1000).toFixed(2)}&nbsp;/&nbsp;{(maxPower / 1000).toFixed(2)} kW
          </div>
            <LinearProgress
          variant="determinate"
          value={progressValue}
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#1BD4ED',
                },
              }}
            />
        </div>
    ); 
  };

  const Rating = (row) => {
    return Object.keys(row).includes('rating') ? (
      <div>{row.rating}</div>
    ) : (
      <></>
    );
  };

  const Phase = (row) => {
    return Object.keys(row).includes('numberOfConnectedPhase') ? (
      <div>
       {row?.numberOfConnectedPhase}
      </div>
    ) : (
      <></>
    );
  };
  const DemandSet = (row) => {
    return Object.keys(row).includes('smartCharging') ? (
      <div>{row?.smartCharging?.setPointkw}</div>
    ) : (
      <></>
    );
  };
  return (
    <HeirachyTable
      ref={dataTableRef}
      className='transaction-history-table'
      rows={data}
      columns={loadcolumns}
      totalRowsCount={state.totalRowsCount}
      onChange={onTableChange}
      defaultSortField={defaultSortColumn}
      defaultSortDescending={true}
      showconnector={showconnector}
      count={state.totalRowsCount}
      limit={state.limit}
      tab={showconnector ? 'Load Balancing' : ''}
      page={page}
      data-cy='demand-view-table'
      tablePagination={true}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    >
      <SiteAreaName slot='siteAreaName' />
      <ChargerName slot='chargerName' />
      <Rating slot='rating' />
      <InstantPowerCell slot='instantkw' />
      <DemandSet slot='demand' />
      <Phase slot='phase' />
    </HeirachyTable>
  );
};
